import React, { useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import ViewerContext from 'context/ViewerContext';
import { useIntl } from 'context/IntlContext';

const CashConversionCycle = () => {
  const { countryCode: viewerCountryCode } = useContext(ViewerContext);
  const { locale } = useIntl();

  useEffect(() => {
    let isMounted = true;

    if (!locale && !viewerCountryCode) return;

    navigate(`/${locale === 'it' ? locale : 'en'}/marketplace#cash_conversion_cycle`, {
      replace: true,
    });

    return () => {
      isMounted = false;
    };
  }, [locale, viewerCountryCode]);

  return <></>;
};

export default CashConversionCycle;
